<template>
    <div v-if="gallery" class="relative w-full overflow-hidden flex items-center justify-center" :style="containerStyles ? containerStyles : {}">
        <div class="relative w-full h-full cursor-pointer" @touchStart="onTouchStart" @touchMove="onTouchMove" @touchStop="onTouchStop">
            <div v-if="isImage(0)" class="w-full h-full">
                <img @click="clickMe(0)" @error="retryImage(0)" :src="getImageUrl(0)" ref="gallery-0" class="object-cover w-full h-full" :loading="imageLoading" :class="{ 'media-max-height': !noMaxHeight }" />
            </div>
            <div v-else-if="isVideo(0)" class="w-full h-full overflow-hidden border" :class="{ 'media-max-height': !noMaxHeight }">
                <div class="w-full h-full overflow-hidden" :class="{ 'media-max-height': !noMaxHeight }">
                    <video-intersect class="w-full h-full" :class="{ 'media-max-height': !noMaxHeight }" style="width: 100% !important; height: auto !important;"
                        :isIntersecting="isIntersecting" :modal="false" :shouldPauseOnExit="pauseOnExit" :src="getVideoUrl(0)" :poster="getPosterUrl(0)" :attrs="videoAttrs"
                        @clickMute="clickMute" @clickMe="clickMe(0)" 
                    />
                </div>
            </div>
        </div>
        <div v-if="gallery.length > 1" @click="clickMe(0)" class="absolute text-gray-800 flex items-center justify-center w-10 h-10 text-xs font-semibold bg-white border rounded-full shadow-md top-10 right-1" style="opacity: 0.67">
            {{ gallery.length }}
        </div>
        <new-gallery v-if="gallery && isFullscreen" v-show="isFullscreen" 
            :gallery="gallery" :initialSlide="initialSlide" 
            @close="isFullscreen = false;" 
        />
    </div>
</template>

<script>
import NewGallery from '@/shared/NewGallery'
import VideoIntersect from '@/shared/VideoIntersect'

export default {
    props: [
        'containerId', 
        'containerStyles',
        'gallery', 
        'isIntersecting', 
        'noMaxHeight',
        'videosMuted'
    ],
    components: {
        NewGallery,
        VideoIntersect
    },
    data() {
        return {
            imageLoading: "eager",
            imageRetries: [],
            initialSlide: 0,
            isFullscreen: false,
            tapping: false
        }
    },
    computed: {
        pauseOnExit() {
            return this.userAutoplay
        },
        userAutoplay() {
            return this?.loggedUser?.settings?.autoplay ?? true
        },
        // userMuted() {
        //     return this?.loggedUser?.settings?.mute ?? true
        // },
        videoAttrs() {
            return {
                autoplay: true, 
                loop: true, 
                playsinline: true, 
                controls: !this.userAutoplay, 
                muted: this.videosMuted //this.userMuted
            }
        },
    },
    methods: {
        clickMe(inIndex) {
            this.initialSlide = inIndex 
            this.isFullscreen = true
        },
        clickMute(inMute) {
            this.$emit('clickMute', inMute)
        },
        getImageUrl(inIndex) {
            return this.transformUrl(this.gallery[inIndex].url, ['optimize', 'lg', 'q80'])
        },
        getPosterUrl(inIndex, inContainerRef) {
            let containerWidth = 0
            let containerHeight = 0

            if (inContainerRef) {
                let myContainer = this.getRectEl(this.$refs[inContainerRef])
                containerWidth = Math.round(myContainer.width)
                containerHeight = Math.round(myContainer.height)
            }
            else {
                let myContainer = this.getRect(this.containerId)
                containerWidth = Math.round(myContainer.width)
                containerHeight = 0
            }

            return this.posterUrl(this.gallery[inIndex].url,['optimize', 'video-md'], containerWidth, containerHeight)
        },
        getVideoUrl(inIndex, inContainerRef) {
            let containerWidth = 0
            let containerHeight = 0

            if (inContainerRef) {
                let myContainer = this.getRectEl(this.$refs[inContainerRef])
                containerWidth = Math.round(myContainer.width)
                containerHeight = Math.round(myContainer.height)
            }
            else {
                let myContainer = this.getRect(this.containerId)
                containerWidth = Math.round(myContainer.width)
                containerHeight = 0
            }
            
            return this.transformUrl(this.gallery[inIndex].url,['video', 'optimize', 'video-md'], containerWidth, containerHeight)
        },
        isImage(inIndex) {
            return this.gallery[inIndex].type == 'image'
        },
        isVideo(inIndex) {
            return this.gallery[inIndex].type == 'video'
        },
        onTouchStart() {
            this.tapping = true
        },
        onTouchMove() {
            this.tapping = false
        },
        onTouchStop(e) {
            if (e.type == "click" || this.tapping) {
                isFullscreen = !isFullscreen
            }

            this.tapping = false
        },
        retryImage(inIndex) {
            if (!this.imageRetries.includes(inIndex) && this.$refs[`gallery-${inIndex}`]) {
                this.imageRetries.push(inIndex)
                this.$refs[`gallery-${inIndex}`].src = null

                this.$nextTick(() => {
                    this.$refs[`gallery-${inIndex}`].src = this.getImageUrl(inIndex)
                })
            }
            else if (this.$refs[`gallery-${inIndex}`]) {
                this.$refs[`gallery-${inIndex}`].classList.add('hidden')
            }
        }
    }
}
</script>

<style>
.media-max-height {
    max-height: 500px;
}
</style>
