<template>
    <div v-if="pages" class="w-full h-full relative flex items-center justify-center">
        <div class="w-full h-full relative" :class="{ 'flex items-start': isDesktopWidth, 'h-screen': !isDesktopWidth }" :style="isDesktopWidth ? { 'max-height': '90%', 'max-width': '90%' } : {}">
            <div class="bg-orange-400 text-gray-800 relative" :class="{ 'w-1/2 h-full': isDesktopWidth, 'w-full h-1/2': !isDesktopWidth }">
                <div v-if="!hideProgress && isDesktopWidth" class="absolute left-0 top-0 p-2 w-full">
                    <div class="flex justify-center">
                        <div class="text-xs font-semibold p-1 rounded-sm h-6 mb-2" :class="{ 'bg-orange-200': currentProgressLabel }">
                            <div v-if="currentProgressLabel">
                                {{ wizardData.pages[currentProgressLabel].heading }}
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-center">
                        <wizard-progress
                            :currentPage="adjustedCurrentPage" :firstNonValidated="firstNonValidated" :ignorePages="ignorePages" :introPages="introPages" :pages="pages" :totalPages="totalPages"
                            @jumpToPage="jumpToPage" @updateProgressLabel="updateProgressLabel"
                        />
                    </div>
                </div>
                <div class="w-full h-full px-2" :class="{ 'flex items-center justify-center': isDesktopWidth }">
                    <div class="w-full h-full">
                        <h3 class="text-center font-bold" :class="{ 'text-xl pb-4 pt-20': isDesktopWidth, 'pb-2 pt-2 px-8': !isDesktopWidth }">
                            {{ wizardDataPage.heading }}
                        </h3>
                        <div class="overflow-y-auto w-full" :style="!isDesktopWidth ? { 'max-height': 'calc(100% - 80px)' } : {}">
                            <div v-if="wizardDataPage.text && wizardDataPage.text.length" class="w-full p-1 text-center">
                                <div v-for="theText in wizardDataPage.text" class="w-full p-1 white-transparent-more mb-1" :class="{ 'text-sm': !isDesktopWidth }">
                                    {{ theText }}
                                </div>
                            </div>
                            <div v-if="wizardDataPage.points && wizardDataPage.points.length" class="w-full text-left">
                                <div v-for="point in wizardDataPage.points" class="flex items-start justify-start" :class="{ 'py-2': isDesktopWidth, 'py-1': !isDesktopWidth }">
                                    <div class="mr-2" :class="{ 'text-2xl': isDesktopWidth }">
                                        &#8226;
                                    </div>
                                    <div class="pt-1" :class="{ 'text-sm': !isDesktopWidth }">
                                        {{ point }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isDesktopWidth" class="w-full absolute left-0 bottom-4">
                    <div v-if="!hideButtons" class="w-full px-2">
                        <div class="w-full">
                            <div v-if="!hidePrevious" @click="previousPage()" class="secondary-button-base mx-1">
                                {{ previousButtonText }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!hideProgress && !isDesktopWidth" class="absolute left-0 bottom-2 flex justify-center w-full">
                    <wizard-progress
                        :currentPage="adjustedCurrentPage" :introPages="introPages" :pages="pages" :totalPages="totalPages"
                        @updateProgressLabel="updateProgressLabel"
                    />
                </div>
            </div>
            <div class="bg-white relative text-gray-800" :class="{ 'flex items-center justify-center w-1/2 h-full': isDesktopWidth, 'w-full h-1/2 pt-2': !isDesktopWidth }">
                <div v-if="!hideLogo && isDesktopWidth" class="absolute left-0 top-4 flex justify-center w-full">
                    <img class="w-20" src="https://imagedelivery.net/9ggDkXOXYMqLgfSEfVmLkQ/44cfb936-9194-4380-779b-419dd1174900/public" />
                </div>
                <div class="overflow-y-auto w-full px-1" :style="isDesktopWidth ? { 'max-height': 'calc(100% - 152px)', 'margin-top': '45px' } : { 'max-height': 'calc(100% - 50px)' }">
                    <slot name="main"></slot>
                </div>
                <div v-if="isDesktopWidth" class="w-full absolute left-0 bottom-4">
                    <div v-if="!hideButtons" class="w-full px-2">
                        <div class="w-full">
                            <div v-if="!hideNext" @click="nextPage()" class="standard-button-base mx-1">
                                {{ nextButtonText }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isDesktopWidth" class="w-full absolute left-0 bottom-4">
                    <div v-if="!hideButtons" class="w-full px-2">
                        <div class="w-full flex items-center justify-between">
                            <div class="w-1/2 px-1">
                                <div v-if="!hidePrevious" @click="previousPage()" class="secondary-button-base mx-1">
                                    {{ previousButtonText }}
                                </div>
                            </div>
                            <div class="w-1/2 px-1">
                                <div v-if="!hideNext" @click="nextPage()" class="standard-button-base mx-1">
                                    {{ nextButtonText }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot name="popups"></slot>
    </div>
</template>

<script>
import WizardProgress from './WizardProgress'

export default {
    props: [
        'checkNonValidated',
        'currentPage',
        'hideLogo',
        'hideNext',
        'hidePrevious',
        'hideProgress',
        'ignorePages',
        'introPages',
        'myPage',
        'outroPages',
        'pages',
        'startPage',
        'wizardData'
    ],
    components: {
        WizardProgress
    },
    data() {
        return {
            currentProgressLabel: null,
            firstNonValidated: null,
            validating: false
        }
    },
    computed: {
        adjustedCurrentPage() {
            return this.introPages ? this.currentPage - this.introPages : this.currentPage
        },
        nextButtonText() {
            return this.pages[this.currentPage].nextText ?? "Continue"
        },
        previousButtonText() {
            return this.pages[this.currentPage].previousText ?? "Back"
        },
        totalPages() {
            return this.objLength(this.wizardData.pages) - (this.introPages ?? 0) - (this.outroPages ?? 0)
        },
        validationAdjuster() {
            return this.introPages ?? 0
        },
        wizardDataPage() {
            return this.wizardData.pages[this.myPage]
        }
    },
    watch: {
        checkNonValidated: {
            handler(val) {
                if (val) {
                    this.$emit('resetCheckNonValidated')
                    this.checkFirstNonValidated()
                }
            }
        }
    },
    mounted() {
        this.checkInitialPage(true)
    },
    methods: {
        async checkFirstNonValidated() {
            let myPromise = new Promise(async (resolve, reject) => {
                let myFirstNonValidated = null

                for (var i = 1, len = this.totalPages; i <= len; i++) {
                    if (!this.pages[i + this.validationAdjuster].validation && myFirstNonValidated == null) {
                        myFirstNonValidated = i
                    }
                }
                
                resolve(myFirstNonValidated)
            })

            await myPromise
                .then((result) => {
                    this.firstNonValidated = result    
                })
                .catch((e) => {

                })
        },
        async checkInitialPage(inJump) {
            await this.checkFirstNonValidated()

            if (inJump) {
                if (this.firstNonValidated && this.firstNonValidated > this.introPages && (!this.startPage || this.startPage > this.firstNonValidated + this.introPages)) { // && this.currentPage < this.introPages + 1
                    this.jumpToPage(this.firstNonValidated + this.introPages)
                }
                else if (this.startPage) {
                    this.jumpToPage(this.startPage)
                }
            }
        },
        jumpToPage(inPageNumber) {
            this.$emit('jumpToPage', inPageNumber)
        },
        nextPage() {
            this.$emit('nextPage')
        },
        previousPage() {
            this.$emit('previousPage')
        },
        updateProgressLabel(inPageNumber, inState) {
            if (inState) {
                this.currentProgressLabel = inPageNumber
            }
            else if (inPageNumber == this.currentProgressLabel) {
                this.currentProgressLabel = null
            }
        }
    }
}
</script>